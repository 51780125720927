body {
  font-family: arial;
  font-size: 12pt;
}

h1 {
  color: #a41d36;
  font-size: 14pt;
  text-align: center;
}

h2 {
  color: #a41d36;
  font-size: 12pt;
}

hr {
  height: 2px;
  background-image: linear-gradient(to right, #fff, #a41d36, #a41d36, #fff);
  border: none;
}

ul {
  list-style: none;
  text-align: left;
  margin-left: 5%;
}

ul li::before {
  content: '\2022';
  color: #a41d36;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.tablaDatos {
  font-family: arial;
  border-spacing: 5px;
  border-top: solid 2px #a41d36;
  color: #565a5c;
  font-size: 10pt;
  text-align: center;
  width: 100%;
}

.tablaDatos td {
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #565a5c;
  border-left: 1px solid #fff;
  vertical-align: top;
}

.tablaDatos tr.ultimoRenglon td {
  border-bottom: 0;
}

.tablaDatos tr:hover {
  background-color: #cfcdcd;
}

.logo {
  max-width: 140px;
  max-height: 30px;
  margin: 10;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  opacity: 1;
  display: block;
  padding: 10px;
}

#iContainer {
  top: 35%;
  display: block;
  position: relative;
  width: 100%;
  height: 30%;
  margin: auto;
  max-width: 500px;
}

#i2 {
  /* transform: translate(-50%,-50%);  */
  z-index: 1;
  width: 80%;
  display: block;
  background-color: rgba(164, 1, 51, 0.7);
  position: relative;
  float: left;
  height: 100%;
}
#i {
  /* transform: translate(-50%,-50%); */
  z-index: 1;
  width: 15%;
  display: block;
  background-color: rgba(164, 1, 51, 0.7);
  position: relative;
  float: right;
  height: 100%;
}
#contrasena {
  text-align: left;
  width: 60%;
  margin-left: 20%;
  min-width: 350px;
}

#headerContainer {
  width: 100%;
  height: 300px;
  min-height: 150px;
  overflow: hidden;
}
#msj {
  background: #d9edf8;
  color: #0071a3;
  border: 1px solid #0071a3;
  border-radius: 4px;
  width: 70%;
  padding: 2px;
  margin: auto;
}
#msjApproved {
  background: #e0f0d8;
  color: #006837;
  border: 1px solid #006837;
  border-radius: 4px;
  width: 70%;
  padding: 2px;
  margin: auto;
}
#msjError {
  background: #e8d3ce;
  color: #aa4c4f;
  border: 1px solid #aa4c4f;
  border-radius: 4px;
  width: 70%;
  padding: 2px;
  margin: auto;
}
#msjWarning {
  background: #fcf8e3;
  color: #de801e;
  border: 1px solid #de801e;
  border-radius: 4px;
  width: 70%;
  padding: 2px;
  margin: auto;
}

#footer {
  font-family: arial;
  color: #fff;
  font-size: 10px;
  background: #a41d36;
  width: 100%;
  height: 30px;
  margin: 0;
  text-align: center;
  padding: 10px 0 0 0;
}

.inputContrasena {
  border: 1px solid #c7c7c7;
  padding: 5px;
  border-radius: 4px;
  max-width: 100%;
  background: #fff;
  float: right;
}
.inputEnviar {
  border: 1px solid #c7c7c7;
  padding: 5px;
  border-radius: 4px;
  max-width: 100%;
  background: #fff;
}

.btn {
  cursor: pointer;
  color: #fff;
  border: 1px solid transparent;
  background-color: #a30931;
  transition: all 0.25s cubic-bezier(0, 0, 0.24, 0.9);
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-width: 120px;
  max-height: 36px;
  padding: 5px;
}

.btn:hover {
  cursor: pointer;
  color: #a41d36;
  border: 1px solid #a41d36;
  background-color: #fff;
  transition: all 0.25s cubic-bezier(0, 0, 0.24, 0.9);
  text-decoration: none;
  text-align: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  min-width: 120px;
  max-height: 36px;
  padding: 5px;
}

.first-div {
  font-family: arial;
  color: fff;
  background: #a41d36;
  width: 100%;
  height: 50px;
  margin: -10 0 0 0;
}

.mt-2 {
  margin-top: -20%;
}

.div-second {
  max-width: 50%;
  min-width: 450px;
  margin: auto;
  color: #565a5c;
  text-align: center;
}

.text-r {
  text-align: right;
}

.reCaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
